import React from 'react';
import './index.css';

export default function App() {
  return (
    <div>
      <div>
        <button type="button" class="add-to-cart-btn">AddToCart</button>
      </div>
      <div>
        <button type="button" class="purchase-btn">Purchase</button>
      </div>
    </div>
  )
}
